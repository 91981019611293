import React, { useEffect } from 'react';
import './LoopingVideo.css';

function LoopingVideo({ onVideoLoad }) {
  useEffect(() => {
    const prefetchLink = document.createElement('link');
    prefetchLink.href = 'https://plix-videos.azureedge.net/videos-public/pedestrians.mp4';
    prefetchLink.rel = 'prefetch';
    prefetchLink.as = 'video';

    document.head.appendChild(prefetchLink);

    return () => {
      document.head.removeChild(prefetchLink);
    };
  }, []);

  return (
    <div className="video-background">
      <video 
        autoPlay 
        loop 
        muted 
        playsInline 
        className="video-fill"
        onCanPlay={onVideoLoad}  // Use the onCanPlay event to signal when the video can start playing
      >
        <source src="https://plix-videos.azureedge.net/videos-public/pedestrians.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default LoopingVideo;
