import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LoopingVideo from './LoopingVideo';
import CareersPage from './Careers';
import './App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [contentOpacity, setContentOpacity] = useState(0);
  const appRef = useRef(null);  // Reference to the main app container

  const handleVideoLoad = () => {
    setIsLoading(false);
    setTimeout(() => {
      setContentOpacity(1);
    }, 200);
  };

  useEffect(() => {
    const checkContentFit = () => {
      const app = appRef.current;
      if (app) {
        const isContentTooTall = app.offsetHeight > window.innerHeight;
        app.style.justifyContent = isContentTooTall ? 'flex-start' : 'center';
        app.style.paddingTop = isContentTooTall ? '20px' : '0';
      }
    };

    window.addEventListener('resize', checkContentFit);  // Check on window resize
    checkContentFit();  // Check on initial load

    return () => window.removeEventListener('resize', checkContentFit);
  }, []);

  return (
    <Router>
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}
      <div ref={appRef} className="App" style={{ opacity: contentOpacity }}>
        <LoopingVideo onVideoLoad={handleVideoLoad} />
        <div className="content">
          <Routes>
            <Route path="/" element={
              <>
                <header>
                  <h1 className="title">Intelligent Body Cameras</h1>
                </header>
                <div className="icon-text-group">
                  <i className="fas fa-bell fa-2x"></i>
                  <p>Smart alerts for situational awareness.</p>
                </div>
                <div className="icon-text-group">
                  <i className="fas fa-cloud fa-2x"></i>
                  <p>Footage securely stored and searchable in the cloud.</p>
                </div>
                <div className="icon-text-group">
                  <i className="fas fa-shield-alt fa-2x"></i>
                  <p>For safety, compliance, and when it matters most.</p>
                </div>
                <div className="button-container">
                  <a href="https://kimv0clgipy.typeform.com/to/O7Vgm410" className="access-button">Request Demo</a>
                </div>
                <div className="contact-careers">
                  <a href="mailto:plix@plix.ai" className="contact-link">Contact plix@plix.ai</a>
                  <Link to="/careers" className="careers-link">Careers</Link>
                </div>
                <footer className="footer">
                </footer>
              </>
            } />
            <Route path="/careers" element={<CareersPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
